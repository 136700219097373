@layer components {
	// TODO MOVE TO components.scss and use active class to show where you are
	#nav-home {
		--color-hue: 60deg;
	}
	#nav-contacts {
		--color-hue: 120deg;
	}
	#nav-settings {
		--color-hue: 180deg;
	}
	#nav-tasks {
		--color-hue: 260deg;
	}
	#nav-logout {
		--color-hue: 360deg;
		width: 100%;
	}

	div#nav-top,
	div#nav-mid,
	div#nav-bot {
		position: relative;
		width: 100%;
	}

	div#nav-top {
		text-align: center;
	}
	div#nav-mid {
		border-top: 1px solid rgb(225, 225, 225);
		border-bottom: 1px solid rgb(225 225 225);
	}
	div#nav-bot {
		position: absolute;
		bottom: 0;
	}

	.reset-btn,
	.no-btn {
		@apply border-none outline-none bg-inherit hover:bg-inherit w-auto m-0 p-0 text-inherit rounded-none;
	}
	.sidebar-icon {
		@apply relative flex items-center justify-center h-20 w-20 mt-2 mb-2 mx-auto shadow-md  text-emerald-500 hover:bg-emerald-500 hover:text-slate-100 rounded-3xl hover:rounded-lg transition-all duration-300 ease-linear cursor-pointer;
	}
	.sidebar-hr {
		@apply mx-1 my-2 rounded-full border bg-slate-200 border-slate-200 dark:bg-dark-800 dark:border-dark-800;
	}
	.tooltip {
		@apply absolute w-auto p-2 min-w-max rounded-md shadow-md text-white bg-zinc-500 text-xs transition-all duration-100 scale-0 z-50;
	}
	.toast {
		@apply flex gap-2 bg-blue-300 text-blue-500 p-4 rounded-lg shadow-lg;
	}
	.small_help {
		@apply text-zinc-400 italic text-xs leading-3 dark:text-gray-400;
	}
	.react-draggable-transparent-selection {
		@apply cursor-grabbing;
	}
	// .select-list {
	::-webkit-scrollbar-track {
		background-color: rgb(147 197 253 / 0.2);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
	}
	// }
	// Styles used in rich text editor frames
	// These styles will vary with the websites styles because these are used in emails
	.rich-toolbar {
		@apply text-lg;
		button {
			@apply p-2 mx-2;
		}
	}
	.rich-editor {
		@apply bg-white;
		h1 {
			@apply text-2xl;
		}
		h2 {
			@apply text-xl;
		}
		h3 {
			@apply text-lg;
		}
		h4 {
			@apply text-base;
		}
		.code {
			@apply font-mono bg-zinc-200;
		}
	}
}

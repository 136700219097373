@import 'grapesjs/dist/css/grapes.min.css';

// Establish layer order
@layer base, components, utilities;

// Import lowest layers
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Theme layers
@import './styles/base.scss';
@import './styles/components.scss';

// Agency layers
// @import '';

div.login-container {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	border-radius: 20px;
	transform: translate(-50%, -50%);
	padding: 50px;
	width: 600px;
}

.settings-btn-save {
	position: absolute;
	bottom: 10px;
}

.required::before {
	content: '*';
	position: relative;
	left: -5px;
}

.bi {
	box-sizing: border-box;
	width: 1em;
	height: 1em;
	vertical-align: -0.125em;
	fill: currentColor;
}

.key {
	font-style: oblique;
	font-size: 0.9em;
}

.contact-relationship {
	fill: var(--relationshop-color, #212529);
}
.contact-manager-key {
	ul {
		list-style: none;
	}
	ul li span {
		position: absolute;
		display: block;
		margin-top: 1px;
		margin-left: -25px;
		padding: 9px;
		background: var(--relationshop-color);
		border-radius: 5px;
	}
}
.home-contact-stats {
	span {
		display: inline-block;
		width: 25px;
		height: 25px;
		background: var(--relationshop-color);
		border-radius: 5px;
		color: white;
	}
}
.contact-relationship-default {
	--relationshop-color: #212529;
}
.contact-relationship-lead {
	--relationshop-color: darkorange;
}
.contact-relationship-customer {
	--relationshop-color: forestgreen;
}
.contact-relationship-connection {
	--relationshop-color: blueviolet;
}

// HTML Builder styling
/* Let's highlight canvas boundaries */
.gjs {
	display: block;
	inset: 0;
	overflow-y: clip;
	height: 100dvh;
}
.gjs * {
	box-shadow: none;
}
#gjs {
	border: 3px solid #444;
}

/* Reset some default styling */
.gjs-cv-canvas {
	top: 0;
	width: 100%;
	height: 100%;
}

.gjs-block {
	width: 45%;
	height: auto;
	min-height: auto;
	.gjs-block__media {
		margin: auto auto 0.5rem auto;
	}
}
.panel__top {
	padding: 0;
	width: 100%;
	display: flex;
	position: static;
	justify-content: center;
	justify-content: space-between;
}
.panel__basic-actions {
	position: initial;
}

.editor-row {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: nowrap;
	height: 100vw;
	background-color: #444;
}

.editor-canvas {
	flex-grow: 1;
}

.panel__right {
	flex-basis: 230px;
	position: relative;
	overflow-y: auto;
	height: 90vh;
}

.panel__switcher {
	position: initial;
}
.panel__devices {
	position: initial;
}

.gjs .row {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: nowrap;
	padding: 10px;
	min-height: 75px;
}
.gjs .row-cell {
	flex-grow: 1;
	flex-basis: 100%;
	padding: 5px;
}

#blocks {
	overflow: auto;
}

/* Theming */

/* Primary color for the background */
.gjs-one-bg {
	background-color: #444;
	// @apply bg-slate-300;
}

/* Secondary color for the text color */
.gjs-two-color {
	color: #ddd;
	// @apply text-zinc-800;
}

/* Tertiary color for the background */
.gjs-three-bg {
	color: #804f7b;
	// @apply bg-slate-200 text-zinc-900;
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
	color: #d278c9;
	// @apply text-amber-800;
}
